<template>
  <WebhooksTable/>
</template>
<script>
import WebhooksTable from '@/generated/ziqni/views/webhooks/WebhooksTable';

export default {
  components: {
    WebhooksTable
  },
  props: {}
};
</script>