<template>
  <!--
    x-vue-csv-supported: false
    x-vue-table-show: true
    fields: [id, spaceName, created, customFields, tags, metadata, postToUrl, triggers, description, name, headers, status]
    x-vue-custom-fields: [ customFields ]
    x-vue-tags: [ tags ]
    x-vue-metadata: [ metadata ]
    -->
  <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="webhooks"
  />
</template>
<script>
  import { webhooks } from '@/config/descriptions/webhooks';
  import { webhooksTexts } from '@/config/pageTexts/webhooks.json';

  export default {
    name: 'WebhooksTable',
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'webhooks/webhooks',
            fields: 'webhooks/fields',
            sortableFields: 'webhooks/sortableFields',
            pages: 'webhooks/pages',
            loading: 'webhooks/loading',
            resultCount: 'webhooks/resultCount',
            totalRecords: 'webhooks/totalRecords',
            originalFields: 'webhooks/originalFields',
            searchableAllFields: 'webhooks/searchableAllFields',
            success: 'webhooks/success',
            message: 'webhooks/message',
          },
          actions: {
            fields: 'webhooks/handleFields',
            delete: 'webhooks/handleDeleteWebhooks',
            reset: 'webhooks/handleClearFilter',
            query: 'webhooks/handleGetWebhooksByQuery',
          },
        },
        settings: {
          describe: { ...webhooks },
          page: { ...webhooksTexts },
        },
        route: {
          create: 'CreateWebhook',
          edit: 'EditWebhook',
          preview: 'PreviewWebhook',
        },
      };
    },
  };
</script>